import React from 'react';

const SiteMoved = () => {
    return (
        <div style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            height: '100vh', // This makes the div take up the full viewport height
            backgroundColor: '#f0f0f0', // Light grey background
            color: '#333', // Dark text for contrast
            fontSize: '20px', // Reasonable default font size
            fontFamily: 'Arial, sans-serif', // A widely available font stack
            textAlign: 'center', // Centers the text horizontally
            padding: '20px', // Adds some padding around the text
        }}>
            <h1>Our Website Has Moved!</h1>
            <p>We've moved to a new website to serve you better. Please update your bookmarks.</p>
            <a href="https://children-dont-suffer-in-silence.webflow.io/"
                style={{
                    marginTop: '20px',
                    display: 'inline-block',
                    backgroundColor: '#007bff', // Bootstrap primary blue
                    color: '#ffffff', // White text for contrast
                    padding: '10px 20px', // Padding inside the button
                    borderRadius: '5px', // Rounded corners for the button
                    textDecoration: 'none', // Removes underline from the link
                    fontSize: '18px', // Slightly larger font size for the button
                }}>
                Visit Our New Site
            </a>
        </div>
    );
}

export default SiteMoved;
