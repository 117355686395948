import React, { Component } from 'react'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    //faYoutube,
    faFacebook,
    //faTwitter,
    faInstagram
} from "@fortawesome/free-brands-svg-icons";
import { faEnvelope } from '@fortawesome/free-solid-svg-icons'


export default class SocialFollow extends Component {
    render() {
        return (
            <div class="social-container">
                <h3>Follow Us:</h3>
                <a href="mailto:childrendontsufferinsilence@gmail.com?
                    subject=Contacting for Information"
                    className="email contact">
                    <FontAwesomeIcon icon={faEnvelope} size="2x" />
                </a>
                <a href="https://www.facebook.com/childrendontsufferinsilence/"
                    className="facebook social">
                    <FontAwesomeIcon icon={faFacebook} size="2x" />
                </a>
                <a href="https://www.instagram.com/childrendontsufferinsilence"
                    className="instagram social">
                    <FontAwesomeIcon icon={faInstagram} size="2x" />
                </a>

            </div>
        )
    }
}
