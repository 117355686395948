import React from 'react';
import './App.css';

import Introduction from './components/introduction'
import SocialFollow from './components/SocialFollow';




function App() {
  return (

    <div id="App">
      <div id="App-wrap">

        <div id="App-main">
          <Introduction />
          <SocialFollow />
        </div>
      </div>
    </div>

  );
}

export default App;
